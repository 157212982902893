import {mapState} from "vuex";
import feather from "feather-icons";

export default {
    name: "productDetail",
    data(){
        return {
            productDetail: '',
            showImg:'',
            detailNav:0,
            selectedSizeIndex:-1,
            selectedSku:'',
        }
    },
    mounted(){
        feather.replace();
        this.initData();
        this.$track("浏览商品详情",this.$route.fullPath);
    },
    computed:{
        ...mapState(['phone'])
    },
    methods:{
        async initData(){
            const productId = this.$route.query.id
            const {data,status} = await this.$request({
                api:this.$api.product.detail,
                url:this.$api.product.detail.url.replace('{id}',productId)
            })
            if(status === 200){
                this.productDetail = data;
                this.showImg = data.colors[0].images[0];
            }
        },
        changePreview(img){
            this.showImg = img;
        },
        selectSize(index){
          this.selectedSizeIndex = index;
          this.getSkuId();
        },

        async getSkuId(){
            let product = this.productDetail;

            const {data,status} = await this.$request({
                api:this.$api.product.sku,
                url:this.$api.product.sku.url.replace('{id}',product.id),
                query:{
                    color:product.colors[0]['id'],
                    size:product.sizes[this.selectedSizeIndex]['id'],
                }
            });
            if(status === 200){
                this.selectedSku = data.id
            }else{
                this.$toast.warning('选取尺码失败，请稍后再试');
            }
        },

        addCart(){
            if(this.phone){
                const product ={
                    id:this.productDetail.id,
                    image:this.productDetail.image,
                    title:this.productDetail.title,
                    price:this.productDetail.price,
                    source:this.productDetail.source,
                    count:1
                }
                this.$store.commit('addCart',{
                    phone:this.phone,
                    product
                });
                alert('已加入购物车');
            }else {
                this.$router.push('/login');
            }
        },
        checkout(){
            if(this.selectedSizeIndex === -1){
                this.$toast.info('请选择尺码',{timeout:2000})
            }
            else if(this.phone){
                const product ={
                    id:this.productDetail.id,
                    sku:this.selectedSku,
                    image:this.productDetail.image,
                    title:this.productDetail.title,
                    price:this.productDetail.price,
                    source:this.productDetail.source,
                    count:1
                }
                this.$store.commit('addCheckoutList',[product]);
                this.$router.push('/checkout')
            }else {
                this.$router.push('/login');
            }
        },

        gotoShop(){
            this.$router.push({path:'/productList',
                query:{label:this.productDetail.title.brand + '旗舰店',brand:this.productDetail.brand.id}
            });
        }
    }
}
