import {mapState} from "vuex";
import feather from 'feather-icons';

export default {
    name: "Header",
    computed:{
        ...mapState(['phone','carts']),
        cartLabel(){
            if(this.phone !== '' && this.carts[this.phone]){
                return `购物车(${this.carts[this.phone].length})`
            }else {
                return '购物车'
            }
        },
    },
    data(){
        return {
            searchContent:'',
            navs:[
                {label:'女士服装',route:{name:'ProductList',query:{label:'女士服装','filter[categories][]':'clothing',sort:'created',gender:'woman'}}},
                {label:'男士服装',route:{name:'ProductList',query:{label:'男士服装','filter[categories][]':'clothing',sort:'created',gender:'man'}}},
                {label:'牛仔裤',route:{name:'ProductList',query:{label:'牛仔裤','filter[categories][]':'denim-pants',sort:'created'}}},
                {label:'T桖',route:{name:'ProductList',query:{label:'T桖','filter[categories][]':'t-shirt',sort:'created'}}},
                {label:'卫衣',route:{name:'ProductList',query:{label:'卫衣','filter[categories][]':'hoodie',sort:'created'}}},
                {label:'外套',route:{name:'ProductList',query:{label:'外套','filter[categories][]':'coat',sort:'created'}}},
                {label:'西装',route:{name:'ProductList',query:{label:'西装','filter[categories][]':'suit',sort:'created'}}},
                {label:'鞋履',route:{name:'ProductList',query:{label:'鞋履','filter[categories][]':'shoes',sort:'created'}}},
                {label:'包袋',route:{name:'ProductList',query:{label:'包袋','filter[categories][]':'bag',sort:'created'}}},
                {label:'配饰',route:{name:'ProductList',query:{label:'配饰','filter[categories][]':'accessory',sort:'created'}}}
            ],
            userName:''
        }
    },
    async mounted(){
        feather.replace();
        await this.$words.getWord();
        this.userName = await this.initUserName();
        this.navs = await this.$words.checkWords(this.navs);
    },
    methods:{
        async initUserName(){
            if(this.phone){
                const phone = await this.$words.checkWords(this.phone)
                return phone.replace('_','');
            }
        },
        gotoCart(){
            if(this.phone){
                this.$router.push({path:'/cart'})
            }else {
                // 未登录
                this.$router.push({path:'/login'})
            }
        },
        search(){
            this.$track("搜索商品",this.$route.fullPath);
            this.$router.push({path:'/productList',query:{keyword:this.searchContent}});
            this.searchContent = '';
        },

        // 检查敏感字
        async checkSensitiveWords(){
            this.searchContent = await this.$words.checkWords(this.searchContent);
        },
        // 退出登录
        logout(){
            if(confirm("您确定退出登录吗？")){
                this.$store.commit('logout');
            }
        }
    }
}