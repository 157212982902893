import axios from 'axios';
import qs from 'qs';
import inappropriateWords from "@/utils/inappropriateWords";

// axios拦截器
axios.interceptors.response.use(
    res => Promise.resolve(res),
    error => {
        return Promise.reject(error.response)
    }
);

/**
 * uni.request封装
 * @param api apiList里面的对象，包括{url,method,needCheck}属性
 * @param url 请求url
 * @param query url拼接参数
 * @param data 请求参数
 * @param requestTimes 第几次请求
 * @returns {Promise<unknown>}
 */
function request({api, url, query, data}, requestTimes = 1) {
    // 只允许重复请求一次，避免无限循环
    if (requestTimes > 2) {
        throw new Error('request again too many times')
    }

    return new Promise((resolve) => {
        // 拼接URL
        const localUrl = url ? url : api.url;
        let newUrl = query ? localUrl + `?${qs.stringify(query)}` : localUrl;
        const needCheck = api.needCheck;
        let token = localStorage.getItem('token') || "";

        axios({
            method: api.method,
            url: newUrl,
            data,
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json',
                AppId: "h5",
            },
        })
            .then(async (res) => {
                if (needCheck) {
                    res = await inappropriateWords.checkWords(res);
                }
                resolve(res);
            })
            .catch(err => {
                resolve(err)
            })
    })
}


export default request;
