export default {
    name: "merchantInfo",
    data(){
        return{
            isUpload:false,
            form:{
                shopName:'',
                address:'',
                name:'',
                phone:'',
                businessLicenseNo:'',
                range:''
            }
        }
    },
    methods:{
        upload(e){
            if(e.target.files.length){
                this.isUpload = true
            }
        },
        commit(){
            let isFillIn;
            Object.values(this.form).forEach(val => {
                if(val === '') isFillIn = true;
            })

            if (isFillIn || !this.isUpload){
                alert("请完整填写相关信息")
            }else {
                this.$track("提交商家入驻申请",this.$route.fullPath);
                alert("提交成功，请等待审核结果，谢谢！")
                this.$router.push('/')
            }
        }
    }
}