<template>
    <div>
        <p class="text-h1">售后政策</p>
        <br><p class="text">尊敬的顾客：因海淘性质特殊，不支持7天无理由退换货与商品保价。请您在下单前仔细阅读本说明：</p>
        <br><p class="text-h3">已付款</p>
        <br><p class="text">未采购可免费取消订单。系统默认1小时自动确认订单，确认后不可再免费取消。</p>
        <br><p class="text-h3">采购中</p>
        <br><p class="text">每次取消订单，需支付订单金额3%的银行手续费。</p>
        <br><p class="text-h3">已采购、境外物流中</p>
        <br><p class="text">每次取消订单，需支付订单金额10%的退货费。</p>
        <br><p class="text">有“不可取消”标签的商品来源于线下买手店，折扣高于一般海淘电商，因此不支持取消或退换。</p>
        <br><p class="text-h3">已发货、境内物流中 待收货、交易成功</p>
        <br><p class="text"> 中转仓发货后，因海淘物流的复杂性及海外商家政策，除确认有影响商品使用的质量问题，其余均不支持退换货。</p>
        <br><p class="text-h3">物流异常</p>
        <br><p class="text">因物流异常导致超30个工作日未到货的，顾客可免费取消订单。 ( 因地震、水灾、火灾、暴风雪、疫情等不可抗力原因而导致的情况除外；因顾客不配合提供与清关相关的身份信息、不配合收件或拒收的的情况除外。)</p>
        <br><p class="text">预售商品，因预售等待期较长，以商家实际发货日开始计算。</p>
        <br><p class="text-h3">增值服务</p>
        <br><p class="text-h3">催采购</p>
        <br><p class="text">可将订单立即变更为“采购中”状态，注意点击后订单不可再免费取消。</p>
        <br><p class="text-h3">尺码测量,拍照服务</p>
        <br><p class="text">订单结算时勾选该项特色服务（任一）后，除页面标记有“不可取消”的商品外，其余商品在中转仓发出前可取消订单，只需扣除49元的国际段退货运费及该项服务费。</p>
        <br><p class="text-h3">换货</p>
        <br><p class="text">采购前均可免费换货；采购后需要按上述标准收费。</p>
        <br><p class="text-h3">修改地址</p>
        <br><p class="text">中转仓发出前，均免费修改地址;</p>
        <br><p class="text">中转仓发出后，需要客人自己联系快递修改，如需要我方代联系，需支付20元／次的修改成本费用。</p>
        <br><p class="text-h3">赔付标准</p>
        <br><p class="text-h3">缺货</p>
        <br><p class="text">换货：免费换货，并提供优惠价格结算作为补偿。</p>
        <br><p class="text">退款：1个工作日内全额退款；并自动发送一张每满1000减50的满减券。</p>
        <br><p class="text-h3">严重质量问题</p>
        <br><p class="text">未使用且包装吊牌完整情况下，免费全额退货退款或换货。</p>
        <br><br>
    </div>
</template>

<script>
export default {
    name: "afterSalesPolicy"
}
</script>

<style scoped>

</style>