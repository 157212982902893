<template>
    <div class="flex-col flex-align-center">
        <p class="text-h1 title">Step1 ： 签订入驻协议</p>
        <div class="text main">
            <p>一、定义</p>
            <p>平台，是指您当前所浏览的网站平台。</p>
            <br>
            <p>用户，包含注册用户和非注册用户，以下亦称为"您"。注册用户是指通过我们平台完成全部注册程序后，使用我们平台服务或我们网站资料的用户。非注册用户是指未进行注册、直接登录我们平台或通过其他网站进入我们平台直接或间接地使用我们平台服务或我们网站资料的用户。</p>
            <br>
            <p>协议方，本协议中协议双方合称"协议方"。</p>
            <br>
            <p>二、协议的效力</p>
            <p>1.在您按照注册页面提示填写信息、阅读并同意本协议完成全部注册程序后，您即受本协议的约束。</p>
            <br>
            <p>2.本协议内容包括本协议正文《隐私政策》，所有我们已经发布的或将来可能发布的规则为本协议不可分割的组成部分，与本协议具有同等法律效力。除另行明确声明外，任何我司网站提供的服务（以下称为"我们平台服务"）均受本协议约束。您承诺接受并遵守本协议的约定。如果您不同意本协议的约定，您应立即停止注册程序或停止使用我们平台服务；如您继续访问和使用我们平台服务，即视为您已确知并完全同意本协议各项内容。</p>
        </div>
        <div class="flex-align-center check" @click="isCheck = !isCheck">
            <div  v-show="!isCheck">
                <i data-feather="square"></i>
            </div>
            <div  v-show="isCheck">
                <i data-feather="check-square"></i>
            </div>

            <p class="text">我已阅读并同意以上协议</p>
        </div>

        <button class="btn" @click="nextStep">下一步</button>
    </div>
</template>

<script>
    import feather from "feather-icons";

    export default {
        name: "signAgreement",
        data(){
          return{
              isCheck:false
          }
        },
        mounted() {
            feather.replace()
        },
        methods:{
            nextStep(){
                if(this.isCheck){
                    this.$track("签订入驻协议",this.$route.fullPath);
                    this.$router.push({path: '/merchantInfo'})
                }else {
                    alert('请阅读并同意相关协议')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        width: 900px;
    }

    .main{
        width: 900px;
        border: solid 1px #dddddd;
        padding: 10px;
        margin-top: 30px;
        font-weight: 300;
    }

    .check{
        margin-top: 32px;
        cursor: pointer;

        svg{
            margin-right: 10px;
        }
    }

    .btn{
        font-size: 16px;
        width: 200px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        border: none;
        background-color: #333;
        color:#fff;
        border-radius: 25px;
        margin: 50px 0;
    }
</style>