import {mapState} from "vuex";

export default {
    name: "cart",
    mounted(){
        this.$track("浏览购物车",this.$route.fullPath);
    },
    computed:{
        ...mapState(['phone','carts']),
        cart(){
            return this.carts[this.phone]
        },
        cartCount(){
            if(!this.cart.length){
                return 0;
            }else {
                let count = 0;
                this.cart.forEach(item => count += Number(item.count));
                return count
            }
        },
        cartTotal(){
            if(!this.cart.length){
                return 0;
            }else {
                let total = 0;
                this.cart.forEach(item => total += item.count*item.price);
                return total
            }
        }
    },
    methods:{
        changeCount(product){
            product.count = Number(product.count);
            this.$store.commit('changeCartCount',{
                phone:this.phone,
                product
            });
        },
        checkout(){
            this.$track("购物车结算",this.$route.fullPath);
            this.$store.commit('addCheckoutList',this.cart);
            this.$router.push('/checkout')
        }
    }
}