import feather from "feather-icons";

export default {
    name: "paginator",
    props:{
        pageCount:{type:Number,default:1}
    },
    data(){
        return{
            curPage:1,
        }
    },
    mounted(){
        feather.replace()
    },
    computed:{
        middlePages(){
            if(this.pageCount <= 2){
                return 0
            }else if(this.pageCount > 2 && this.pageCount <= 10){
                return this.pageCount - 2;
            }else{
                return this.curPage > 999 ? 5 : 8;
            }
        },
        bigLimit(){
            return this.middlePages > 5 ? this.pageCount - 6 : this.pageCount - 3;
        },
        offset(){
            if(this.curPage <= 5){
                return 2;
            }else if(this.curPage >= this.bigLimit){
                return this.bigLimit - 2;
            }else{
                return this.middlePages > 5 ? this.curPage - 3 : this.curPage - 2;
            }
        }
    },
    methods:{
        resetPage(){
            this.curPage = 1;
        },
        togglePage(index){
            this.$emit('togglePage',index);
            this.curPage = index;
        },
        prevPage(){
            if(this.curPage !== 1){
                this.togglePage(this.curPage - 1);
            }
        },
        nextPage(){
            if(this.curPage !== this.pageCount){
                this.togglePage(this.curPage + 1);
            }
        }
    }
}