import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request/index'
import apiList from './request/apiList'
import inappropriateWords from './utils/inappropriateWords'
import trackAccessLog from './utils/track'
import Toast,{POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css"

Vue.config.productionTip = false;

Vue.prototype.$request = request;
Vue.prototype.$api = apiList;
Vue.prototype.$words = inappropriateWords;
Vue.prototype.$track = trackAccessLog;

const toastOptions = {
  draggable: false,
  position:POSITION.TOP_CENTER,
  hideProgressBar: true,
};
Vue.use(Toast,toastOptions);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

