import feather from "feather-icons";

export default {
    name: "pay",
    data() {
        return {
            orderId:this.$route.query.orderId,
            amount:this.$route.query.amount,
            //qrUrl:''
            payUrl:'',
            hadPaid:false,
        }
    },

    mounted() {
        this.initPay()
    },

    methods:{
        async initPay(){
            let requestData = {
                app: 'me2',
                env: "pc",
                locale: 'me1global',
                payment: 'alipay',
                amount: this.amount,
                trade: this.orderId,
                openId: '',
            };

            const {data, status} = await this.$request({
                api: this.$api.pay,
                data:requestData,
            });
            if (status === 200) {
                console.log(data);
                this.payUrl = data.url
            }
        },

        viewOrder(){
            this.$router.push({path:'/orderDetail',query:{id:this.orderId}});
        },

        payLinkHandle(){
            this.hadPaid = true;
        }
    }
}
