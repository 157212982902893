import store from '../store'
import request from '../request/index'
import apiList from '../request/apiList'

/**
 * uni.request封装
 * @param operation 用户行为
 * @param url 访问路径
 * @returns {Promise<unknown>}
 */
async function trackAccessLog(operation,url){
    const username = store.state.phone || "游客";
    const baseUrl = window.location.host;
    url = "https://" + baseUrl + "/#" + url;

    // 上报日志
    await request({
        api:apiList.admin.reportLogs,
        data:{
            username,operation,url
        }
    })
}

export default trackAccessLog;