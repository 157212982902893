import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index/index.vue'
import productList from "@/views/productList/productList.vue";
import productDetail from "@/views/productDetail/productDetail.vue";
import login from "../views/login/login.vue";
import merchant from "../views/merchant/merchant.vue";
import signAgreement from "../views/merchant/signAgreement/signAgreement.vue";
import merchantInfo from "../views/merchant/merchantInfo/merchantInfo.vue";
import userAgreement from "../views/userAgreement/userAgreement.vue";
import cart from "../views/cart/cart.vue";
import checkout from "../views/checkout/checkout.vue";
import pay from "../views/pay/pay.vue";
import contact from "@/views/contact/contact.vue";
import introduction from "@/views/introduction/introduction.vue";
import afterSalesPolicy from "@/views/afterSalesPolicy/afterSalesPolicy.vue";
import helpCenter from "@/views/helpCenter/helpCenter.vue";
import orderList from "@/views/orderList/orderList.vue";
import orderDetail from "@/views/orderDetail/orderDetail.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: index
  },
  {
    path: '/productList',
    name: 'ProductList',
    component: productList
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: productDetail
  },
  {
    path: '/login',
    name: 'Login',
    component: login
  },
  {
    path: '/merchant',
    name: 'Merchant',
    component: merchant,
  },
  {
    path:'/signAgreement',
    name: 'SignAgreement',
    component:signAgreement
  },
  {
    path:'/merchantInfo',
    name: 'MerchantInfo',
    component:merchantInfo
  },
  {
    path:'/userAgreement',
    name: 'UserAgreement',
    component:userAgreement
  },
  {
    path:'/cart',
    name: 'Cart',
    component:cart
  },
  {
    path:'/checkout',
    name: 'Checkout',
    component:checkout
  },
  {
    path:'/pay',
    name:'Pay',
    component:pay
  },
  {
    path:'/orderList',
    name:'OrderList',
    component:orderList
  },
  {
    path:'/orderDetail',
    name:'OrderDetail',
    component:orderDetail
  },
  {
    path:'/contact',
    name: 'Contact',
    component:contact
  },
  {
    path:'/introduction',
    name: 'Introduction',
    component:introduction
  },
  {
    path:'/afterSalesPolicy',
    name: 'AfterSalesPolicy',
    component:afterSalesPolicy
  },
  {
    path:'/helpCenter',
    name: 'HelpCenter',
    component:helpCenter
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to,from,next)=>{
  const isLogin = !!localStorage.getItem('phone');
  if(isLogin) next()
  else {
    if(to.name === 'Cart' || to.name === 'Checkout') next({name:'Login'})
    else next()
  }
});

export default router
