import feather from "feather-icons";

export default {
    name: "orderDetail",
    data() {
        return {
            order:'',
            orderId: this.$route.query.id,
        }
    },
    mounted() {
        feather.replace();
        this.initOrder()
    },
    methods: {
        async initOrder() {
            const {data, status} = await this.$request({
                api: this.$api.order.detail,
                url:this.$api.order.detail.url.replace('{id}',this.orderId)
            })
            if (status === 200) {
                this.order = data;
            }
        },

        async pay(){
            this.$router.push({
                path: '/pay',
                query: {
                    orderId: this.orderId,
                    amount: this.order.payment.total
                }
            })
        },

        cancelOrder(){
            this.$toast.info('此功能还未完善，请填加微信联系客服进行取消：18664723537',{timeout:8000})
        }

    }
}
