<template>
    <div>
        <p class="text-h1">商品来源</p>
        <br><p class="text">迷衣与全球Top100的大型时尚电商官网、以及过百家欧洲知名买手店合作，囊括3,000多种从高奢到小众的品牌，合计收录了超过3,000,000＋款时尚正品，每日上新至少5,000＋款新品。</p>
        <br><p class="text-h3">物流无忧</p>
        <br><p class="text">迷衣自营香港中转仓，提供商品质检服务，为您的正品转运保驾护航；商品抵达中转仓后优先采用跨境电商线快捷清关，非预售商品正常情况1-2周左右送达；物流全程透明可查。</p>
        <br><p class="text-h3">售后政策</p>
        <br><p class="text">因海淘性质特殊，不支持7天无理由退换。但如您对商品尺寸或其它细节存在疑虑，可在下单时勾选“尺码测量”或“拍照服务”，我们将在商品抵达自营中转仓后，为您提供具体的商品数据或实物图。</p>
        <br><p class="text-h3">价格说明</p>
        <br><p class="text">价格包税：收件地址为中国大陆地区的用户所看到的订单总价，即为本次购物所需支付的全部费用，无需额外支付税费；收件地址为中国大陆以外地区的用户，可在下单时按退税价格支付，但可能产生的关税和相关手续由用户自理。</p>
        <br><p class="text">价格变动：迷衣实时链接全球各大时尚电商、买手店的价格，价格受合作伙伴的定价策略调整、促销活动、汇率等因素影响而实时波动。因各大电商与买手店之间可能有同款商品在售，所以在迷衣，您可在同款商品不同价格的多个链接中选择最优的价格下单。</p>
        <br><p class="text">价格划线：商品展示的划横线价格为参考价格，而非原价。该参考价格可能是商品吊牌价或由商品原销售地使用的正品零售价（如厂商指定价、建议零售价等）、品牌方指导价、合作伙伴在原销售地的实体店铺中的展示价或该商品在本平台曾经展示过的销售价。由于地区、时间的差异性以及行情和汇率波动，该划线价仅供您参考。</p>
        <br><br>
    </div>
</template>

<script>
export default {
    name: "helpCenter"
}
</script>

<style scoped>

</style>