import feather from "feather-icons";

let timer = null;

export default {
    name: "login",
    data() {
        return {
            isLoginPage: true,
            phone: '',
            password: '',
            password2: '',
            isCheck: false,

            code: '',//验证码
            disableGetVerificationCode: false,
            countDownSecond: 60,
        }
    },
    mounted() {
        this.isLoginPage = !this.$route.query.isRegister;
        feather.replace();
        this.$track("登录页", this.$route.fullPath);
    },
    beforeDestroy() {
        if (timer) {
            clearTimeout(timer)
        }
    },
    methods: {
        /*async login(){
            if(this.phone.includes('*')){
                alert("你的账号包含敏感词！");
                return;
            }

            if(this.phone && !this.phone.includes('*') && this.password){
                const {status,data} = await this.$request({
                    api:this.$api.admin.login,
                    data:{
                        username:this.phone,
                        password:this.password
                    }
                })
                console.log(status)
                if(status === 200){
                    this.$track("登录成功",this.$route.fullPath);
                    alert('登录成功');
                    this.$store.commit('login', this.phone);
                    this.$router.back();
                }else if(status === 401){
                    alert(data.err);
                }else {
                    alert("登录失败");
                }
            }else {
                alert('请输入手机和密码')
            }
        },
        async register(){
            if(this.isCheck){
                if(this.phone.includes('*')){
                    alert("你的账号包含敏感词！请重新输入！");
                    this.phone = '';
                    return;
                }

                if(this.phone && this.password && this.password2){
                    if(this.password.length < 6){
                        alert('密码不少于6位')
                    }else if(this.password !== this.password2){
                        alert('请输入一致的密码')
                    }else {
                        const {status,data} = await this.$request({
                            api:this.$api.admin.register,
                            data:{
                                username:this.phone,
                                password:this.password
                            }
                        })
                        if(status === 200){
                            this.$track("注册成功",this.$route.fullPath);
                            alert('注册成功');
                            this.checkPage();
                        }else if(status === 400){
                            alert(data.err);
                        }else {
                            alert("注册失败");
                        }
                    }
                }else {
                    alert('请输入注册手机与密码')
                }
            }else {
                alert('请阅读并同意《用户服务协议》')
            }

        },*/
        checkPage() {
            this.phone = '';
            this.password = '';
            this.password2 = '';
            this.isLoginPage = !this.isLoginPage;
        },

        async checkKeyWord() {
            this.phone = await this.$words.checkWords(this.phone);
        },

        //获取验证码
        async getCode() {
            if (!this.disableGetVerificationCode) {
                if (this.phone === "") {
                    this.$toast.warning("请输入手机号", {
                        timeout: 1500
                    });
                } else {
                    await this.getVerificationCode();
                }
            }
        },

        //获取验证码
        async getVerificationCode() {
            try {
                const {status} = await this.$request({
                    api: this.$api.user.authCode,
                    data: {
                        phone: this.phone
                    }
                });
                if (status === 200) { //正常获取才开始显示60秒倒计时
                    this.disableGetVerificationCode = true;
                    this.countDown();
                }

                let toastText = status === 200 ? '验证码已发送' : '验证码发送失败';
                this.$toast.info(toastText, {
                    timeout: 1500
                });
            } catch (err) {
                console.log(err);
            }
        },

        //倒计时
        countDown() {
            if (this.countDownSecond === 0) {
                this.countDownSecond = 60;
                this.disableGetVerificationCode = false
            } else {
                this.countDownSecond--;
                timer = setTimeout(this.countDown, 1000);
            }
        },

        //手机号登录
        async login() {
            const phone = this.phone;
            const code = this.code;

            if (phone && code) {
                let {status} = await this.loginByPhoneAndCode(phone, code);
                if (status) {
                    this.$router.back();
                } else {
                    this.$toast.warning('登录失败，请稍后再试', {timeout: 2000})
                }
            } else {
                this.$toast.warning('请输入手机号和验证码', {timeout: 1500})
            }
        },

        //登录请求
        async loginByPhoneAndCode(phone, code) {
            return new Promise(async (resolve) => {
                try {
                    const res = await this.$request({
                        api: this.$api.user.phone,
                        data: {
                            phone, code,
                            platform: 'chillcy-web',
                            openId: '',
                        }
                    });
                    const {data} = res;
                    console.log(data);
                    if (data.token) {
                        this.handleLoginResult(data);
                        resolve({status: true});
                    } else {
                        resolve({status: false, msg: data.message});
                    }
                } catch (err) {
                    console.log(err);
                    resolve({status: false, msg: '网络异常'});
                }
            })
        },

        //处理登录返回信息，更新localstorage
        handleLoginResult(loginRes) {
            try {
                this.$store.commit('login', {
                    phone: this.phone,
                    token: loginRes.token
                });
            } catch (err) {
                console.log(err)
            }
        },
    }
}
