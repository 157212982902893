import { render, staticRenderFns } from "./Paginator.vue?vue&type=template&id=01498a49&scoped=true"
import script from "./Paginator.js?vue&type=script&lang=js&external"
export * from "./Paginator.js?vue&type=script&lang=js&external"
import style0 from "./Paginator.scss?vue&type=style&index=0&id=01498a49&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01498a49",
  null
  
)

export default component.exports