<template>
    <div>
        <p class="text-h1">企业介绍</p><br>
        <p class="text"> 奇思是一家基于大数据技术的时装比价购物平台；</p><br>
        <p class="text">现已整合各地约3000个时尚大牌&小众设计师品牌，超过3,000,000＋件时尚折扣正品，助您一站购全世界的好价时尚。</p><br>
        <p class="text-h3">【正品来源，实时比价】</p><br>
        <p class="text">奇思实时连接各地Top100的大型时尚电商官网、欧洲知名买手店，收录超过3,000,000件时尚单品；</p><br>
        <p class="text">为你提供同款、相似款单品的同一时间来自世界海外商家的不同价格，助您放心买到实惠。</p><br>
        <p class="text-h3">【大牌一折起，订阅降价及时通知】</p><br>
        <p class="text">Burberry、Gucci、Prada、Versace等国际大牌低至1折起，Balenciaga巴黎世家老爹鞋2500元起，Chloé蔻依女装不过千元；</p><br>
        <p class="text">您可选择在奇思订阅您喜爱设计师品牌、单品，以便及时收到折扣上新与单品降价通知。</p><br>
        <p class="text-h3">【海量上新，个性时尚一网打尽】</p><br>
        <p class="text">每日上新至少5,000＋款来自各地的时尚新品，囊括来自时装周、秀场、明星私服、街拍的同款正品来源；</p><br>
        <p class="text">基于各地海量上新，再难找，再稀罕的个性时尚单品都能在奇思找到,而且经常有1-3折的私密折扣活动,性价比超高!</p><br>
        <p class="text-h3">【物流、清关无忧】</p><br>
        <p class="text">奇思自营香港、澳门中转仓，提供商品质检服务，为您的正品转运保驾护航；</p><br>
        <p class="text">商品抵达中转仓后优先采用跨境电商线快捷清关，非预售商品平均7天送达；物流全程透明可查。</p><br><br>
    </div>
</template>

<script>
export default {
    name: "introduction"
}
</script>

<style scoped>

</style>