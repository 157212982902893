import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const phone = localStorage.getItem('phone');
const carts = localStorage.getItem('carts');
const words = localStorage.getItem('words');
const token = localStorage.getItem('token');

export default new Vuex.Store({
    state: {
        phone: phone || '',
        carts: carts ? JSON.parse(carts) : {},
        checkout: '',
        words: words ? JSON.parse(words) : []
    },
    mutations: {
        // 登录&注册
        login(state, res) {
            console.log('store',res)
            state.phone = `_${res.phone}`;
            state.token = res.token;
            localStorage.setItem('phone', `_${res.phone}`);
            localStorage.setItem('token', res.token);
        },

        // 退出登录
        logout(state) {
            state.phone = '';
            state.token = '';
            localStorage.setItem('phone', '');
            localStorage.setItem('token', '');
        },

        // 保存敏感词
        saveWords(state, words) {
            state.words = words;
            localStorage.setItem('words', JSON.stringify(words))
        },

        // 加入购物车
        addCart(state, info) {
            const phone = info.phone;
            const product = info.product;

            if (state.carts[phone] && state.carts[phone] !== null) {
                const cart = state.carts[phone];
                const isInCart = cart.findIndex(item => item.id === product.id);

                if (isInCart === -1) {
                    cart.push(product);
                } else {
                    cart[isInCart].count += 1;
                }
                state.carts[phone] = cart;
            } else {
                state.carts[phone] = [product];
            }

            localStorage.setItem('carts', JSON.stringify(state.carts));
        },
        // 修改购物车商品数目
        changeCartCount(state, info) {
            const phone = info.phone;
            const product = info.product;

            let cart = state.carts[phone];
            if (product.count === 0) {
                cart = cart.filter(item => item.id !== product.id);
            } else {
                const isInCart = cart.findIndex(item => item.id === product.id);
                cart[isInCart] = product;
            }

            state.carts[phone] = cart;
            localStorage.setItem('carts', JSON.stringify(state.carts));
        },

        // 结算
        addCheckoutList(state, products) {
            state.checkout = products;
        }
    }
})
