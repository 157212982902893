<template>
  <div class="footer">
    <footer class="flex-col-center text-small text-white">
      <div class="flex-row-between">
        <div>
          Copyright © {{ year }} 广州奇思信息科技有限公司版权所有
        </div>
        <div>
          <div>
            <router-link to="/introduction" class="text-white">企业介绍</router-link>
            <span class="line">|</span>
            <router-link to="/afterSalesPolicy" class="text-white">售后政策</router-link>
          </div>
        </div>

      </div>
      <div class="flex-row-between">
        <p>广州市海珠区广州大道南1601号之十八自编205（仅限办公） | 020-31950265 </p>
        <div>
          <router-link to="/helpCenter" class="text-white">帮助中心</router-link>
          <span class="line">|</span>
          <router-link to="/contact" class="text-white">联系我们</router-link>
        </div>
      </div>

      <div class="flex-align-center" style="padding: 5px 0 20px">
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502002151"
           class="flex-align-center">
          <img src="../../assets/images/footer/备案图标.png" style="width:15px;margin-right: 5px; margin-bottom: 2px" />
          <p class="text-small text-white">粤公网安备
            44010502002151号</p></a>
        <div style="padding: 0 10px">|</div>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" class="text-small text-white" target="_blank">
          粤ICP备2020093530号
        </a>
        <div style="padding: 0 10px">|</div>
        <a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E7%25B2%25A4B2-20201840&type=xuke"
           class="text-small text-white" target="_blank">
          粤B2-20201840
        </a>

      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  min-width: 1200px;
  background-color: #444444;
}

footer {
  padding-top: 20px;
  max-width: 1200px;
  font-weight: 300;
  margin: auto;
}

.line {
  padding: 0 10px;
}

@media (max-width: 1200px) {
  footer {
    padding: 0 20px;
  }
}
</style>
