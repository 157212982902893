import feather from "feather-icons";

export default {
    name: "orderList",
    data() {
        return {
            orders: [],
        }
    },
    mounted() {
        feather.replace();
        this.initOrders()
    },
    methods: {
        async initOrders() {
            const {data, status} = await this.$request({
                api: this.$api.order.list,
            })
            if (status === 200) {
                this.orders = data.trades;
            }
        },

        viewOrder(orderId){
            this.$router.push({path:'/orderDetail',query:{id:orderId}});
        }
    }
}
