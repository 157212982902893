import { render, staticRenderFns } from "./merchantInfo.vue?vue&type=template&id=4c576be8&scoped=true"
import script from "./merchantInfo.js?vue&type=script&lang=js&external"
export * from "./merchantInfo.js?vue&type=script&lang=js&external"
import style0 from "./merchantInfo.scss?vue&type=style&index=0&id=4c576be8&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c576be8",
  null
  
)

export default component.exports