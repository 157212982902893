import Paginator from './components/Paginator/Paginator.vue'
import ProductItem from '@/components/ProductItem/ProductItem.vue'
import feather from "feather-icons";

export default {
    name: "productList",
    components:{
        Paginator,ProductItem
    },
    data(){
        return{
            filterIndex:0,
            query:{},
            products:[],
            pageCount:0,
            tips:'暂无相关商品结果。'
        }
    },
    watch: {
        $route() {
            this.query = this.$route.query;
            if (this.query.keyword && this.query.keyword.includes('*')){
                this.tips = '您输入的信息含有敏感词。';
            }else {
                this.getData();
                this.filterIndex = 0;
                if(this.pageCount){
                    this.$refs.paginator.resetPage();
                }
                this.tips = '暂无相关商品结果。';
            }
        }
    },
    mounted(){
        feather.replace()
        this.query = this.$route.query;
        if (this.query.keyword && this.query.keyword.includes('*')){
            this.tips = '您输入的信息含有敏感词。';
        }else {
            this.getData();
            this.tips = '暂无相关商品结果。';
        }
        this.$track("浏览商品列表",this.$route.fullPath);
    },
    methods:{
        async getData(){
            this.query.limit = 8;
            // this.query.source = "ssense";
            const {data,status} =  await this.$request({
                api:this.$api.product.list,
                query:this.query,
            })
            if(status === 200){
                this.products = data.products;
                this.pageCount = data.pagination.endPage > 20 ? 20 : data.pagination.endPage;
            }
        },

        // 筛选器
        filter(i){
            if(this.filterIndex !== i){
                this.filterIndex = i;
                this.query.sort = !i ? 'create'
                    : (i === 1 ? 'discount' : 'price');
                this.query.page = 1;
                this.getData();
                this.$refs.paginator.resetPage();
            }
        },

        // 翻页
        togglePage(page){
            this.query.page = page;
            this.getData();
        }
    }
}
