<template>
    <div id="app" class="flex-col">
        <Header v-show="currentPage!== 'Pay'"/>
        <div class="flex-full">
        <router-view class="flex-full router-view"/>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import Header from '@/components/Header/Header.vue'
    import Footer from '@/components/Footer/Footer.vue'
    import feather from 'feather-icons';

    export default {
        name: 'app',
        components: {
            Header,
            Footer
        },
        data() {
            return {
                currentPage: '',
            }
        },
        beforeCreate() {
            this.$words.getWord();
        },
        mounted() {
            feather.replace();
            this.currentPage = this.$route.name
        },


    }
</script>

<style lang="scss">
    @import "/style/components";

    // 全局样式
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: #333;
    }

    a:hover, button:hover, input:focus, textarea:focus {
        outline: none;
    }


    button {
        cursor: pointer;
    }

    button:focus {
        outline: none;
    }

    #app {
        min-height: 100vh;
    }

    .router-view {
        width: 1200px;
        margin: auto;
    }
</style>
