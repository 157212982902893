import {mapState} from "vuex";
import feather from "feather-icons";

export default {
    name: "checkout",
    computed: {
        ...mapState(['checkout']),
        ...mapState(['phone']),
        total() {
            let total = 0;
            this.checkout.forEach(item => total += item.price * item.count);
            return total;
        }
    },
    mounted() {
        feather.replace();
        this.$track("结算页", this.$route.fullPath);
        console.log('sku', this.checkout[0].sku)
    },
    data() {
        return {
            addressId: '',//保存地址后返回

            fullname: '',
            province: '',
            city: '',
            district: '',
            address: '',
            mobile: '',


            payerName: '',
            payerIdCard: '',

            payMethod: '',

            orderId: '',
            amount: '',
        }
    },
    methods: {
        async submitOrderHandle() {
            if (this.addressId === '') {
                this.$toast.warning("请先保存地址", {timeout: 2000})
            } else if (this.payerName === '' || this.payerIdCard === '') {
                this.$toast.warning("请补充支付人信息", {timeout: 2000})
            } else {
                await this.createOrder();
                this.toPayHandle();
            }
        },

        saveAddressHandle() {
            if (this.fullname === '' || this.province === '' || this.city === ''
                || this.district === '' || this.address === '' || this.mobile === '') {
                this.$toast.warning('请先填写完整的收货人信息', {timeout: 2000})
            } else {
                this.saveAddress();
            }

        },

        async saveAddress() {
            const {data, status} = await this.$request({
                api: this.$api.user.addAddress,
                data: {
                    name: this.fullname,
                    mobile: this.mobile,
                    country: '中国',
                    province: this.province,
                    city: this.city,
                    district: this.district,
                    address: this.address,
                    default: true,
                }
            });
            if (status === 201) {
                this.addressId = data.id;
                this.$toast.success('保存成功', {timeout: 2000});
                console.log(this.addressId)
            }
        },

        async createOrder() {
            const {data, status} = await this.$request({
                api: this.$api.order.create,
                data: {
                    action: 'CREATE',
                    env: 'pc',
                    skuId: this.checkout[0].sku,
                    addressId: this.addressId,
                    coupon: '',
                    idCard: {
                        idCardNum: this.payerIdCard,
                        idCardName: this.payerName,
                    }
                }
            });
            if (status === 201) {
                this.orderId = data.id;
                await this.getOrder();
            }
        },

        toPayHandle() {
            console.log('amount', this.amount);
            if (this.amount) {
                this.$router.push({
                    path: '/pay',
                    query: {
                        orderId: this.orderId,
                        amount: this.amount
                    }
                })
            } else {
                this.$toast.warning('创建订单失败，请稍后再试')
            }
        },

        async getOrder() {
            return new Promise(async (resolve) => {
                const {data, status} = await this.$request({
                    api: this.$api.order.detail,
                    url: this.$api.order.detail.url.replace('{id}', this.orderId)
                });
                if (status === 200) {
                    this.amount = data.payment.total;
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        }
    }

}
