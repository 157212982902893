import { render, staticRenderFns } from "./orderList.vue?vue&type=template&id=2ad1a191&scoped=true"
import script from "./orderList.js?vue&type=script&lang=js&external"
export * from "./orderList.js?vue&type=script&lang=js&external"
import style0 from "./orderList.scss?vue&type=style&index=0&id=2ad1a191&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ad1a191",
  null
  
)

export default component.exports