import ProductItem from '@/components/ProductItem/ProductItem.vue'
import { Swiper, Slide } from 'vue-swiper-component';

export default {
    name: "index",
    components:{
        ProductItem,
        Swiper,
        Slide
    },
    data(){
        return{
            banners:[
                'https://old-subject.cdn.chillcy.com/FkonW8QgtbUe12VQDi45n_PGyeiF',
                'https://old-subject.cdn.chillcy.com/FjmTBh3ic1IjwVtfgCRNgWaLhyKw',
                'https://old-subject.cdn.chillcy.com/FpYro0bGMb5ava-iGxpbB7LmKn9L'
            ],
            categories:[
                {
                    label:'女士服装',
                    query:{'filter[categories][]':'clothing',gender:'woman'},
                    products:[]
                },
                {
                    label:'男士服装',
                    query:{'filter[categories][]':'clothing',gender:'man'},
                    products:[]
                },
                {
                    label:'牛仔裤',
                    query:{'filter[categories][]':'denim-pants'},
                    products:[]
                },
                {
                    label:'T桖',
                    query:{'filter[categories][]':'t-shirt'},
                    products:[]
                },
                {
                    label:'卫衣',
                    query:{'filter[categories][]':'hoodie'},
                    products:[]
                },
                {
                    label:'外套',
                    query:{'filter[categories][]':'coat'},
                    products:[]
                },
                {
                    label:'鞋履',
                    query:{'filter[categories][]':'shoes'},
                    products:[]
                },
                {
                    label:'包袋',
                    query:{'filter[categories][]':'bag'},
                    products:[]
                },
                {
                    label:'配饰',
                    query:{'filter[categories][]':'accessory'},
                    products:[]
                }
            ]
        }
    },
    async mounted(){
        await this.$words.getWord();
        this.categories = await this.$words.checkWords(this.categories);
        this.initData();
        this.$track("浏览首页",this.$route.fullPath);
    },
    methods:{
        async initData(){
            for(let category of this.categories){
                const query = category.query;
                query.limit = 4;
                // query.source = "ssense";
                const {data,status} = await this.$request({
                    api:this.$api.product.list,
                    query
                })
                if(status === 200){
                    category.products = data.products;
                }
            }

        }
    }
};
