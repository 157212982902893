<template>
  <router-link :to="{name:'ProductDetail',query:{id:product.id}}" class="flex-col-center flex-align-center">
    <img class="product-image" :src="product.image" alt="product.title.cn">
    <p class="text-small text-grey">{{ product.title.brand }}旗舰店</p>
    <p class="text text-brand">{{ product.title.en || product.title.brand }}</p>
    <p class="text">{{ product.title.cn }}</p>
    <p class="text">￥{{ product.price }}</p>
  </router-link>
</template>

<script>
export default {
  name: "productItem",
  props: {
    product: {type: Object, require: true}
  }
}
</script>

<style lang="scss" scoped>
.product-image {
  width: 240px;
  height: 300px;
  object-fit: contain;
  margin: 30px 0 10px;
}
</style>