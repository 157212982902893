<template>
  <div>
    <p class="text-h1">联系我们</p>
    <br>
    <p class="text-h1 text-light">广州奇思信息技术有限公司</p>
    <br>
    <p class="text-h3 text-normal">联系电话：020-31950265</p>
    <br>
    <p class="text-h3 text-normal"> 联系邮箱：yunnieliu@chillcy.com</p>
    <br>
    <p class="text-h3 text-normal">联系地址：广州市海珠区广州大道南1601号创投小镇6号楼205</p>
    <br>
    <p class="text-h3 text-normal">营业执照：</p>
    <br>
    <img src="https://asset.cdn.chillcy.com/zurich/wecom-temp-2eaad7f28254f446feb1f1fca14a5ee0-faef6a9ffc.jpg" alt="营业执照">
  </div>
</template>

<script>
export default {
  name: "contact"
}
</script>

<style scoped>
.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

img {
  width: 50%;
  margin-bottom: 50px;
}
</style>
