import request from "@/request";
import apiList from "@/request/apiList";
import store from "@/store"

const inappropriateWords = {
    async getWord(){
        const {data,status} = await request({
            api:apiList.admin.inappropriateWords
        });
        if(status === 200){
            store.commit('saveWords',data);
        }
    },

    async checkWords(data){
        const inappropriateWords = store.state.words;
        let isObj = false;

        if(typeof data === 'object'){
            data = JSON.stringify(data);
            isObj = true;
        }

        // 替换敏感词
        inappropriateWords.forEach(item =>{
            const reg = new RegExp(item.word,"g");
            // 判断内容中是否包含敏感词
            if(data.includes(item.word)) {
                data = data.replace(reg, item.substitute);
            }
        })

        if(isObj){
            return JSON.parse(data);
        }else {
            return data;
        }
    }
};

export default inappropriateWords;