/**
 * 接口url配置，baseUrl通过env文件指定
 *
 * 每个路由包括三部分
 * url：请求路径
 * method：请求方法
 * needCheck：是否需要授权，在封装的request方法里会针对需要授权的请求作处理
 */

const productApi = '/productApi';
const adminApi = '/adminApi';
const userApi = '/userApi';
const payApi = '/payApi';

export default {
    /*
    * 商品
    * */
    product: {
        // 商品列表
        list: {
            url: productApi + '/api/products',
            method: 'get',
            needCheck: true
        },
        // 商品详情
        detail: {
            url: productApi + '/api/products/{id}',
            method: 'get',
            needCheck: true
        },
        //获取
        sku: {
            url: productApi + "/api/products/{id}/skus",
            method: "get",
            needAuth: false
        },
    },
    user: {
        // 获取验证码
        authCode: {
            url: userApi + "/api/auth-code",
            method: "post",
            needCheck: true
        },
        // 通过手机号加验证码方式登录
        phone: {
            url: userApi + "/api/login/phone",
            method: "post",
            needCheck: true
        },

        addAddress:{
            url: userApi + "/api/user/addresses",
            method: "post",
            needCheck: true
        }
    },

    order: {
        //下单预览 请求参数action:CREATE
        create: {
            url: userApi + "/api/user/trades",
            method: "POST",
            needCheck: true
        },
        list:{
            url: userApi + "/api/user/trades",
            method: "get",
            needAuth: true
        },
        detail:{
            url: userApi + "/api/user/trades/{id}",
            method: "get",
            needAuth: true
        }
    },

    pay: {
        url: payApi + "/api/pay",
        method: "post",
        needAuth: true
    },

    admin: {
        // 注册
        register: {
            url: adminApi + '/api/register',
            method: 'post',
            needCheck: false
        },
        // 登录
        login: {
            url: adminApi + '/api/login',
            method: 'post',
            needCheck: false
        },
        // 获取敏感词
        inappropriateWords: {
            url: adminApi + '/api/inappropriate-words',
            method: 'get',
            needCheck: false
        },
        // 上报日志
        reportLogs: {
            url: adminApi + '/api/access-logs',
            method: 'post',
            needCheck: false
        }
    }
}
